import React from 'react'
import { Link } from "gatsby"
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import LogoImage from "src/images/logo.svg"

import Settings from 'src/styles/settings'

import { Media } from 'src/styles/mixins'

const Container = styled.div`

  ${Media.desktop`
    margin: 0 auto 0 0;
  `}

  ${Media.mobile`
    order: 2;
  `}
`;

const Inner = styled(Link)`
`;

const Image = styled(LogoImage)`
  height: 40px;

  ${Media.mobile`
    height: 2rem;
  `}
`;

const Logo = ({ settings }) => (
  <Container>
    <Inner to="/" title={ settings.site }>
      <Image />
    </Inner>
  </Container>
)

Logo.propTypes = {
  settings: PropTypes.object,
}

export default Logo
