import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled, { css } from 'styled-components'

import Settings from 'src/styles/settings'

import IcoSearch from "src/images/ico_search.svg"
import IcoClose from "src/images/ico_close.svg"

import { Media } from 'src/styles/mixins'

const Container = styled.div`

  ${Media.desktop`
    position: relative;
    padding-left: 1.5rem;
    margin-left: 1rem;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      height: 14px;
      margin-top: calc(-14px / 2);
      border-left: 1px solid ${Settings.colors.keyColor};
    }
  `}

  ${Media.mobile`
    order: 3;
    margin-left: auto;

    ${props => props.focus && css`
      display: block;
      position: absolute;
      left: 0;
      top :0;
      width: 100%;
      height: 60px;
      background-color: ${Settings.colors.baseColor};
    `}
  `}
`;

const Form = styled.form`
  display: flex;
  margin: 0;

  ${Media.mobile`
    position: relative;
    z-index: 2;
    align-items: center;
    height: 100%;
  `}
`;

const Label = styled.label`
  ${Media.desktop`
    cursor: pointer;
  `}

  ${Media.mobile`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
  `}
`;

const Image = styled(IcoSearch)`
  ${Media.mobile`
    height: 2rem;
  `}
`;

const Content = styled.div`
`;

const Input = styled.input`
  display: block;
  padding: 0;
  background: none;
  border: none;
  height: 100%;
  width: 0;
  outline: none;
  font-size: ${Settings.fonts.fontSizeInput};
  font-family: ${Settings.fonts.defaultFontFamily};

  ${Media.desktop`
    transition: width .3s ease;

    ${props => props.focus && css`
      width: 12rem;
      padding: 0 .5em;
      margin-left: 1rem;
    `}
  `}

  ${Media.mobile`
    ${props => props.focus && css`
      width: 100%;
      height: 2rem;
    `}
  `}
`;

const Button = styled.div`

  ${Media.desktop`
    display: none;
  `}

  ${Media.mobile`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    margin-left: auto;
  `}
`;

const CloseImage = styled(IcoClose)`

  ${Media.mobile`
    height: 2rem;
  `}
`;

const Result = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 100%;
  background-color: ${Settings.colors.baseColor};
  z-index: 1;
  border-radius: .5rem;

  ${Media.desktop`
    width: 300px;
  `}

  ${Media.mobile`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 60px;
    height: 100%;
  `}
`;

const ResultTitle = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${Settings.colors.borderColor};
`;

const ResultList = styled.ul`
  list-style: none;
  margin: 0;
  padding: .5rem 0;
  max-height: 50vh;
  overflow: auto;
`;

const ResultItem = styled.li`
`;

const ResultLink = styled(Link)`
  display: block;
  padding: .5rem 1rem;
  text-decoration: none;
  color: ${Settings.colors.keyColor};
`;

const Search = () => {
  const [focus, setFocus] = useState(false);

  const triggerFocus = () => {
    setFocus( !focus )
  }

	const tempData = useStaticQuery(graphql`
		query SearchData {
			allContentfulPosts(
        filter: {node_locale: {eq: "ja-JP"}},
        sort: {fields: createdAt, order: DESC}
      ) {
				edges {
				node {
					title
					slug
					createdAt(formatString: "YYYY-MM-DD")
				}
				}
			}
		}
  `)

	const allPosts = tempData.allContentfulPosts.edges
	const emptyQuery = ""
	const [state, setState] = useState({
	  filteredData: [],
	  query: emptyQuery,
  })

	const handleInputChange = event => {
	  console.log(event.target.value)
	  const query = event.target.value
	  const posts = tempData.allContentfulPosts.edges || []

	  const filteredData = posts.filter(post => {
	    const title = post.node.title
	    const excerpt = post.node.excerpt
	    const content = post.node.content
	    return (
	      title.toLowerCase().includes(query.toLowerCase())
	    )
	  })
	  setState({
	    query,
	    filteredData,
	  })
	}
	const { filteredData, query } = state
	const hasSearchResults = filteredData && query !== emptyQuery
	const result = hasSearchResults ? filteredData : allPosts

  return (
    <Container focus={ focus && 'focus' }>
      <Form>
        <Label onClick={ triggerFocus } htmlFor="focus">
          <Image />
        </Label>
        <Content>
          <Input id="focus" focus={ focus && 'focus' } type="search" placeholder="検索するキーワード" onChange={ handleInputChange } />
        </Content>
        { focus &&
          <Button onClick={ triggerFocus }>
            <CloseImage />
          </Button>
        }
      </Form>

      { query &&
        <Result>
          { result.length !== 0 && (
            <ResultTitle>
              { query } の検索結果
              { result.length } 件の記事があります
            </ResultTitle>
          )}
          <ResultList>
            {result && result.map(({ node: post }) => {
              return (
                <ResultItem key={post.slug}>
                  <ResultLink to={`/${post.slug}`}>
                    {post.title}
                  </ResultLink>
                </ResultItem>
              )
            })}
          </ResultList>
        </Result>
      }
    </Container>

  )
}

export default Search
