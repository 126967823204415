import { css } from 'styled-components';
import Settings from './settings'

export const Media = {
  mobile: (...args) => css`
    @media (max-width: ${Settings.sizes.mbWidth}) {
      ${css(...args)};
    }
  `,

  desktop: (...args) => css`
    @media (min-width: ${Settings.sizes.pcWidth}) {
      ${css(...args)};
    }
  `,
};

export const Neumorphism = css`
  border-radius: .5rem;
  background: ${Settings.colors.baseColor};
  box-shadow: .5rem .5rem 1rem ${Settings.colors.shadowColor}, -.5rem -.5rem 1rem ${Settings.colors.whiteColor};
`;

export const DropShadow = css`
  box-shadow: .5rem .5rem 1rem ${Settings.colors.shadowColor};
`;
