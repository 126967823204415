import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import styled, { css } from 'styled-components'

import IcoMenu from "src/images/ico_menu.svg"
import IcoClose from "src/images/ico_close.svg"

import Settings from 'src/styles/settings'

import { Media, Neumorphism } from 'src/styles/mixins'

const Menu = styled.button`

  ${Media.desktop`
    display: none;
  `}

  ${Media.mobile`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    padding: 0;
    background: none;
    order: 1;
    margin-right: auto;
    border-width: 0;
  `}
`;

const MenuImage = styled(IcoMenu)`

  ${Media.mobile`
    height: 2rem;
  `}
`;

const Container = styled.nav`

  ${Media.mobile`
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${Settings.colors.baseColor};
    display: none;

    ${props => props.opened && css`
      display: block;
    `}
  `}
`;

const Button = styled.div`

  ${Media.desktop`
    display: none;
  `}

  ${Media.mobile`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
  `}
`;

const Image = styled(IcoClose)`

  ${Media.mobile`
    height: 2rem;
  `}
`;

const Title = styled.h1`

  ${Media.desktop`
    display: none;
  `}

  ${Media.mobile`
    margin: 0;
    padding: 0 1rem;
    margin-bottom: .5rem;
    font-size: 1.143rem;
  `}
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  ${Media.desktop`
    display: flex;

    ${props => props.mobile && css`
      display: none;
    `}
  `}

  ${Media.mobile`
    margin-bottom: 1rem;
  `}
`;

const Item = styled.li`

  ${Media.mobile`
    display: inline-block;
    width: 49%;
    font-size: 1rem;
  `}
`;

const ItemLink = styled(Link)`
  display: block;
  color: ${Settings.colors.keyColor};
  text-decoration: none;

  ${Media.desktop`
    padding: .4rem 1rem;
    border-radius: 100px;
    transition: box-shadow ${Settings.sizes.sec} ease;

    &:hover {
      ${Neumorphism};
    }
  `}

  ${Media.mobile`
    padding: .5rem 1rem;
  `}
`;

const Copyright = styled.div`

  ${Media.desktop`
    display: none;
  `}

  ${Media.mobile`
    margin: 0 1rem;
    color: ${Settings.colors.textLightColor};
  `}
`;

const Nav = ({ menus, settings }) => {
  const [open, setOpen] = useState(false);

  const triggerOpen = () => {
    setOpen( !open )
  }

  return (
    <>
      <Menu onClick={ triggerOpen }>
        <MenuImage />
      </Menu>

      <Container opened={ open && 'opened' }>
        <Button onClick={ triggerOpen }>
          <Image />
        </Button>

        <Title>カテゴリー</Title>
        <List>
            { menus && menus.map(({ node: menu }) => (
              ( menu.group == 1 &&
                <Item key={ menu.id }>
                  <ItemLink to={`/tag/${ menu.slug }`} title={ menu.title } dangerouslySetInnerHTML={{ __html: menu.title }} />
                </Item>
              )
            ))}
        </List>
        <Title>種類</Title>
        <List mobile>
            { menus && menus.map(({ node: menu }) => (
              ( menu.group == 2 &&
                <Item key={ menu.id }>
                  <ItemLink to={`/tag/${ menu.slug }`} title={ menu.title } dangerouslySetInnerHTML={{ __html: menu.title }} />
                </Item>
              )
            ))}
        </List>
        <Title>メニュー</Title>
        <List mobile>
          <Item><ItemLink to="/about" title="ペトラとは？">ペトラとは？</ItemLink></Item>
          <Item><ItemLink to="/privacy" title="プライバシーポリシー">プライバシーポリシー</ItemLink></Item>
        </List>
        <Copyright>© Petra</Copyright>
      </Container>
    </>
  )
}

Nav.propTypes = {
  settings: PropTypes.object,
  menus: PropTypes.array,
}

export default Nav
