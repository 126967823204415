import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Settings from 'src/styles/settings'

import { Media } from 'src/styles/mixins'

import Logo from 'src/components/molecules/Logo'
import Nav from 'src/components/molecules/Nav'
import Search from 'src/components/molecules/Search'

const Container = styled.header`

  ${Media.desktop`
    height: 160px;
  `}

  ${Media.mobile`
    height: 60px;
    border-top: 4px solid ${Settings.colors.keyColor};
  `}
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1068px;
  height: 100%;
  margin: 0 auto;

  ${Media.desktop`
    padding: 0 1rem;
  `}

  ${Media.mobile`
  `}
`;

const Header = ({ settings, menus }) => (
  <Container role="banner">
    <Inner>
      <Logo settings={ settings } />
      <Nav settings={ settings } menus={ menus } />
      <Search />
    </Inner>
  </Container>
)

Header.propTypes = {
  settings: PropTypes.object,
  menus: PropTypes.array,
}

export default Header
