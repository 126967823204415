import { rgba, darken, lighten } from 'polished'

const color = {
  greenColor: '#31AC84',
  blackColor: '#000',
  whiteColor: '#fff',
  redColor: '#F5707B',
  blueColor: '#7FC8DF',
  yellowColor: '#F2BA04',
  brownColor: '#817860',
  facebookColor: '#3b5999',
  twitterColor: '#55acee',
  lineColor: '#4ecd00',
  instagramColor: '#262626',

  keyColor: '#31AC84',
  baseColor: '#FCF8FB',
}

export default {
  colors: {
    keyColor: color.keyColor,
    baseColor: color.baseColor,

    whiteColor: color.whiteColor,
    blackColor: color.whiteColor,
    yellowColor: color.yellowColor,
    redColor: color.redColor,
    blueColor: color.blueColor,
    facebookColor: color.facebookColor,
    twitterColor: color.twitterColor,
    lineColor: color.lineColor,
    instagramColor: color.instagramColor,

    keyClearColor: rgba(color.keyColor, 0.6),
    linkColor: darken(0.2, color.keyColor),
    bgColor: rgba(color.blackColor, 0.2),
    grayColor: rgba(color.blackColor, 0.34),
    grayLightColor: rgba(color.blackColor, 0.04),
    shadowColor: rgba(color.blackColor, 0.08),

    textColor: rgba(color.blackColor, 0.8),
    textLightColor: rgba(color.blackColor, 0.54),

    borderColor: rgba(color.blackColor, 0.08),
    borderSolidColor: lighten(0.76, color.blackColor),
    whiteZeroColor: rgba(color.whiteColor, 0),
    whiteClearColor: rgba(color.whiteColor, 0.8),
    yellowClearColor: rgba(color.yellowColor, 0.32),
  },

  sizes: {
    siteWidth: '1060px',
    mainWidth: '840px',
    sideWidth: '220px',
    pcWidth: '600px',
    mbWidth: '599px',
    sec: '.16s',
    defaultLineHeight: '1.6',
  },

  fonts: {
    fontSizePc: '16px',
    fontSizeMb: '14px',
    fontSizeInput: '16px',
    fontSizeXxsmall: '.68rem',
    fontSizeXsmall: '.86rem',
    fontSizeSmall: '.94rem',
    fontSizeMedium: '1.2rem',
    fontSizeLarge: '1.4rem',
    fontSizeXlarge: '1.8rem',
    fontSizeXxlarge: '2.4rem',
    defaultFontFamily: '"Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN", "Yu Gothic Medium", "游ゴシック Medium", "YuGothic", "游ゴシック体", "Meiryo", "メイリオ", sans-serif',
    defaultFontFamilyEn: 'lato, sans-serif',
  }
}
