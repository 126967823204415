import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'

import Settings from 'src/styles/settings'
import { Media } from 'src/styles/mixins'

import Header from 'src/components/organisms/Header'

const GlobalStyle = createGlobalStyle`
  ::selection {
    background: ${Settings.colors.keyColor};
    color: ${Settings.colors.baseColor};
  }

  ::-moz-selection {
    background: ${Settings.colors.keyColor};
    color: ${Settings.colors.baseColor};
  }

  html {
    ${Media.desktop`
      font-size: 16px;

      &::before, &::after {
        display: block;
        position: fixed;
        z-index: 1;
        background-color: ${Settings.colors.keyColor};
        content: "";
      }

      &::before {
        top: 0;
        left: 0;
        width: 100vw;
        height: .5rem;
      }

      &::after {
        top: 0;
        right: 0;
        width: .5rem;
        height: 100vh;
      }
    `}

    ${Media.mobile`
      font-size: 14px;
    `}
  }

  body {
    margin: 0;
    background-color: ${Settings.colors.baseColor};
    line-height: 1.6;
    font-family: ${Settings.fonts.defaultFontFamily};
    letter-spacing: 1px;

    ${Media.desktop`
      padding: .5rem;

      &::before, &::after {
        display: block;
        position: fixed;
        z-index: 1;
        background-color: ${Settings.colors.keyColor};
        content: "";
      }

      &::before {
        bottom: 0;
        left: 0;
        width: 100vw;
        height: .5rem;
      }

      &::after {
        top: 0;
        left: 0;
        width: .5rem;
        height: 100vh;
      }
    `}
  }

  .infinite-scroll-component {
    overflow: inherit!important;
  }
`;

const Layout = ({
  children,
}) => (
  <StaticQuery
    query={ graphql`
      query {
        allContentfulTags(
          filter: { node_locale: { eq: "ja-JP" }},
          sort: { fields: order, order: ASC },
          limit: 30
        ) {
          edges {
            node {
              id
              title
              slug
              group
              order
            }
          }
        }

        allContentfulPages(
          filter: {
            node_locale: { eq: "ja-JP" }
          }
        ) {
          edges {
            node {
              id
              title
              slug
            }
          }
        }

        contentfulSettings(node_locale: {eq: "ja-JP"}) {
          siteName
          siteCaption
          siteDescription
          twitter
          facebook
          instagram
          line
        }
      }
    `}

    render={ data => (
      <>
        <GlobalStyle />
        <Header
          settings={ data.contentfulSettings }
          menus={ data.allContentfulTags.edges } />

        { children }

      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
